<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5 v-if="modeFilterable">{{ $route.name }}</h5>
              <h5 v-else>
                {{ $store.state.sysenv.codePropagated.name }}
                <small>{{ $store.state.sysenv.codePropagated.parentName }}</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd"></button>
                <div v-if="modeFilterable" class="j_search type01">
                  <input v-model="search" type="search" name="q" placeholder="Filter and search" />
                  <button type="submit">
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </div>
                <div v-else class="view_type">
                  <v-btn class="j_button type03" @click="onModeList">
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                  <v-btn class="j_button type03" @click="onModeThumbs">
                    <v-icon>mdi-view-grid</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="virtualSn"
            hide-actions
            @sorted="onSorted"
            @filtered="onFiltered"
            :headers="headers[gridMode]"
            :items="chartItems"
            :search="search"
            class="chart"
          >
            <template v-slot:items="props">
              <tr v-if="modeFilterable" :active="props.selected" @click="propStatus(props)">
                <td style="text-align: center">{{ props.item.no }}</td>
                <td>{{ props.item.PhaseName }}</td>
                <td>{{ props.item.GroupName }}</td>
                <td>{{ props.item.ItemName }}</td>
                <td>{{ props.item.SubItemName }}</td>
                <td style="text-align: center">{{ props.item.ChartNo }}</td>
                <td>{{ props.item.Name }}</td>
                <td>{{ props.item.ChartType }}</td>
                <td>{{ props.item.Filtering }}</td>
                <td>{{ props.item.DbAccessName }}</td>
                <td>{{ props.item.MasterDesc }}</td>
              </tr>

              <tr v-if="modeThumbs" :active="props.selected">
                <td style="text-align: center">{{ props.item.no }}</td>
                <td>{{ props.item.subItemName || '─' }}</td>
                <td>
                  <button
                    v-for="chart in props.item.JsonProps"
                    v-ripple
                    flat
                    large
                    fab
                    style="border-radius: 50%"
                    :key="chart.ChartNo"
                    @click="onChartSvgClick(chart)"
                  >
                    <j-chart-svg :json-props="chart.svg" />
                  </button>
                </td>
              </tr>

              <tr v-if="modeList" :active="props.selected" @click="propStatus(props)">
                <td style="text-align: center">{{ props.item.no }}</td>
                <td>{{ props.item.SubItemName }}</td>
                <td style="text-align: center">{{ props.item.ChartNo }}</td>
                <td>{{ props.item.Name }}</td>
                <td>{{ props.item.ChartDb }}</td>
                <td>{{ props.item.Filtering }}</td>
                <td>{{ props.item.DbAccessName }}</td>
                <td>{{ props.item.MasterDesc }}</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <component 
        v-model="formOpened"
        :is="formModalComponent"
        :key-value="keyValue"
        @close="onClose"
        @updated="onUpdated"
      />

    </div>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import vue2Dropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"

import { ChartLibraryService, SystemService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"

export default {
  name: 'sysenv-media-manager',
  components: {
    JFormModal,
    vueDropzone: vue2Dropzone
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
  },
  data: () => ({
    systemService: null,
    chartLibraryService: null,
    formOpened: false,
    formModalComponent: null,
    gridMode: 'F', // ['F', 'T', 'L']
    keyValue: '',
    search: '',
    selected: [],
    headers: {
      F: [
        { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 50, },
        { type: 'text', text: 'Phase', align: 'left', value: 'PhaseName', sortable: true, width: 120, },
        { type: 'text', text: 'Menu Group', align: 'left', value: 'GroupName', sortable: true, width: 120, },
        { type: 'text', text: 'Menu Item', align: 'left', value: 'ItemName', sortable: true, width: 120, },
        { type: 'text', text: 'Sub-Item', align: 'left', value: 'SubItemName', sortable: true, width: 120, },
        { type: 'text', text: 'Chart No.', align: 'center', value: 'ChartNo', sortable: true, width: 120, },
        { type: 'text', text: 'Chart Title', align: 'left', value: 'Name', sortable: true, width: 190, },
        { type: 'text', text: 'Chart Type', align: 'left', value: 'ChartType', sortable: true, width: 150, },
        { type: 'text', text: 'Filter', align: 'left', value: 'Filtering', sortable: true, width: 50, },
        { type: 'text', text: 'Database', align: 'left', value: 'DbAccess', sortable: true, width: 50, },
        { type: 'text', text: 'Desciption', align: 'left', value: 'Desc', sortable: true },
      ],
      T: [
        { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 50, },
        { type: 'text', text: 'Sub-Item', align: 'left', value: 'SubItemName', sortable: true, width: 150, },
        { type: 'text', text: '', align: 'left', value: 'JsonProps', sortable: false },
      ],
      L: [
        { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 50, },
        { type: 'text', text: 'Sub-Item', align: 'left', value: 'SubItemName', sortable: true, width: 120, },
        { type: 'text', text: 'Chart No.', align: 'center', value: 'ChartNo', sortable: true, width: 120, },
        { type: 'text', text: 'Chart Title', align: 'left', value: 'Name', sortable: true, width: 190, },
        { type: 'text', text: 'Chart Type', align: 'left', value: 'ChartType', sortable: true, width: 150, },
        { type: 'text', text: 'Filter', align: 'left', value: 'Filtering', sortable: true, width: 50, },
        { type: 'text', text: 'Database', align: 'left', value: 'DbAccess', sortable: true, width: 50, },
        { type: 'text', text: 'Desciption', align: 'left', value: 'Desc', sortable: true },
      ],
    },
    chartItems: []
  }),
  watch: {
    codePropagated: {
      handler(val) {
        // Menu Item : modeThumbs 에서 Phase Menu 로 이동시 목록 Mode 초기화
        if (val.code == 'ALL') this.modeThumbs = false; this.modeFilterable = true; this.modeList = false;

        this.getChartList()

        if (!val.parentCode || val.parentCode == 'ALL' || val.code == 'ALL') this.modeFilterable = true
        else this.modeList = true
      },
      deep: true
    }
  },
  computed: {
    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(val) { this.$store.commit('sysenv/codePropagate', val) }
    },
    modeFilterable: {
      get() { return this.gridMode == 'F' },
      set(val) { this.gridMode = 'F' }
    },
    modeThumbs: {
      get() { return this.gridMode == 'T' },
      set(val) { this.gridMode = 'T' }
    },
    modeList: {
      get() { return this.gridMode == 'L' },
      set(val) { this.gridMode = 'L' }
    },
    path2catCode() {
      let path2code_ = {
        '/sysenv/config/lib/pdfbg': 'PDF_BG',
      }
      return path2code_[this.$route.path]
    },
  },
  methods: {
    setAdd() {
      this.onModalRequested({ catCode: this.path2catCode, chartNo: '' })
    },
    getChartList(chartNo = null, chartF_) {
      this.chartLibraryService.getChartList(
        this.path2catCode,
        this.codePropagated.parentCode,
        this.codePropagated.code,
        chartNo || 'ALL',
        chartNo ? chartF_ : this.setChartList
      )
    },
    setChartList(res) {
      if (!res) res = []
      this.chartItems = res
    },
    onChartSvgClick(props) {
      // this.getChartList(props.chartNo, res => { this.propStatus({ item: res[0] }) })
    },
    onClose() {
      // this.formOpened = false
      this.keyValue = null
      this.formModalComponent = null
    },
    onModalRequested(chartInfo) {
      this.formModalComponent = __C.CHART.CAT_CODE_COMPONENT[chartInfo.catCode] || null

      // Need little delay time to be done creating the component
      // before running.
      setTimeout(() => {
        this.keyValue = chartInfo.chartNo
        this.formOpened = true
      })
    },
    onModeList() {
      this.getChartList()
      this.modeList = true
    },
    onModeThumbs() {
      // this.chartLibraryService.getChartListThumbs(this.codePropagated.code, (res) => {
      //   this.chartItems = res
      //   this.modeThumbs = true
      // })
    },
    onFiltered(items) {
      let reNumbering = 0
      items.forEach(_item => {
        this.chartItems.find(
          _item_ => _item_.ChartNo == _item.ChartNo
        ).no = ++reNumbering
      })
    },
    onSorted(items) {
      items.forEach((item, index) => {
        item.no = index + 1
      })
    },
    onUpdated() {
      if (this.modeThumbs) this.onModeThumbs()
      else this.getChartList()
    },
    propStatus(props) {
      this.onModalRequested({ catCode: this.path2catCode, chartNo: props.item.ChartNo })
    }
  },
}
</script>
